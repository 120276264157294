.glitch {
    position: relative;

    // no select
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    // glitch effect
    .line {
        &:not(:first-child) {
            position: absolute;
            top: 0;
            left: 0;
        }

        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                animation:
                    clip 1000ms $i * -300ms linear infinite,
                    glitch#{$i} 1000ms random(10000) * -1ms linear infinite;

                @keyframes glitch#{$i} {
                    0% {
                        transform: translateX(0);
                    }
                    82% {
                        transform: translateX(0);
                        // color: $howl-colors-orange200;
                    }
                    83% {
                        transform: translateX(random(200) - 100px);
                        // color: $howl-colors-black800;
                    }
                    93% {
                        transform: translateX(random(200) - 100px);
                        // color: $howl-colors-black800;
                    }
                    94% {
                        transform: translateX(random(200) - 100px);
                        // color: $howl-colors-green300;
                    }
                    95% {
                        transform: translateX(random(200) - 100px);
                        // color: $howl-colors-orange200;
                    }
                    100% {
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    @keyframes clip {
        0% {
            clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
        }

        100% {
            clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
        }
    }
}
