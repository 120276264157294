* {
    text-decoration: none;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

html,
body {
    margin: 0;
    padding: 0;
}

a {
    color: #666;
    text-decoration: none;
}

main {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
}

.wrapper {
    margin: 0 32px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    .content {
        margin: 0 auto;
        padding: 0;
        max-width: 740px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        h1 {
            font-size: 38px;
            color: #000;
            font-weight: 800;
            margin: 0 0 16px 0;
        }

        h2 {
            font-size: 28px;
            color: #000;
            font-weight: 800;
            margin: 0 0 16px 0;
        }

        h5 {
            font-size: 15px;
            color: #333;
            font-weight: 400;
            margin: 0 0 12px 0;
        }

        p {
            font-size: 15px;
            color: #666;
            font-weight: 300;
            margin: 0 0 12px 0;
        }

        a {
            color: #000;
            font-size: inherit;
            font-weight: inherit;
            text-decoration: none;
            transition: all 0.2s ease-in-out;

            &.flex {
                display: flex;
                align-items: center;
                gap: 12px;
                color: #777;
                margin-top: 32px;

                &:hover {
                    color: #000;
                    gap: 16px;
                }
            }
        }
    }
}
