.headerWrapper {
    margin: 16px 32px;
    flex: 0 1;

    .header {
        justify-content: space-between;
        align-items: center;
        display: flex;
        max-width: 740px;
        margin: 0 auto;
        gap: 2rem;

        .home {
            color: #000 !important;
            font-size: 16px;
            font-weight: 600;
        }

        .section {
            display: flex;
            align-items: center;

            a {
                color: #666;
                font-size: 15px;
                transition: all 0.2s ease-in-out;
                text-decoration: none;

                &:hover,
                &.active {
                    color: #000;
                }

                &:not(:last-child) {
                    margin-right: 1.5rem;
                }
            }
        }

        .settings {
            color: #a9a9a9;
            font-size: 20px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: #000;
            }
        }
    }
}

.menu {
    margin-top: 6px;
    background-color: transparent;

    .paper {
        background-color: transparent;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        text-shadow: none;
        border-radius: 8px;
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #dedede;
        min-width: 175px;
        color: #000;

        .section {
            color: #bfbfd9;
            display: flex;
            flex-direction: column;
            background: #fff;
            overflow: hidden;

            &:not(:last-child) {
                border-bottom: 1px solid #dedede;
            }
        }

        .action {
            color: #000;
            cursor: pointer;
            background-color: #fff;
            transition: all 0.2s ease-in-out;
            padding: 0.5rem 0.7rem;

            &:hover {
                background-color: #f9f9f9;
            }
        }
    }

    b {
        font-weight: 600;
        font-size: 15px;
        color: #000;
    }

    span {
        font-size: 14px;
        color: #666;
        font-weight: 400;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.flex {
    display: flex;
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;

    .pfp {
        width: 35px;
        height: 35px;
        border-radius: 6px;
        margin-right: 1rem;
    }

    .col {
        display: flex;
        flex-direction: column;
    }
}
