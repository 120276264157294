.footerWrapper {
    margin: 16px 32px;
    flex: 0 1;

    .footer {
        justify-content: space-between;
        align-items: center;
        display: flex;
        max-width: 740px;
        margin: 0 auto;
        gap: 2rem;
        font-size: 14px;

        .git {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            svg {
                color: #000;
            }

            a {
                color: #666;
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                &:hover {
                    color: #000;
                }
            }
        }
    }
}
