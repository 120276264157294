.hiddenTextarea {
    position: absolute;
    inset: 0;
    resize: none;
    background-color: transparent;
    padding: 0.5rem;
    font-family: monospace;
    color: transparent;
    caret-color: black;
    outline: none;
    font-size: 12px;
}

.codeBlockWrapper {
    position: relative;
    display: flex;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .heading {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;

        @media screen and (max-width: 700px) {
            gap: 0;
        }

        img {
            border-radius: 50%;

            witdh: 60px;
            height: 60px;

            @media screen and (max-width: 700px) {
                display: none !important;
            }
        }

        .col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 8px;

            b {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: #666;
            }
        }
    }

    .editorRoot {
        overflow: hidden;
        overflow-y: auto;
        flex: 1;

        cursor: text;
    }
}
